import * as React from "react"
import Layout from "../../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Helmet from 'react-helmet'

const svgbg = {
	backgroundColor: "#f36f31",
}
const smm = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'single-page whitemain'
    }}
/>
  <Layout pageTitle="smm">
    <section id="main">
      <div id="worktitle">
        <span>
          <h1>South Melbourne Market</h1>
          <span className="highlight"></span>
        </span>
        <span>City of Port Phillip</span>
      </div>
    </section>

    <section id="work">
      <div className="work">
          <div className="picture workpicture hasSVG" style={svgbg}>
		  	<img src={'/img/works/smm.svg'} alt="South Melbourne Market" />

          </div>

        <div className="seeWork">
          <div className="seeWork__content">
            <div className="icons">
              <img className="picture" src={'/img/single/wordpress-icon.png'} width="139" height="87" alt="Wordpress" />
              <img className="picture" src={'/img/single/woocommerce-icon.png'} width="247" height="50" alt="Woocommerce" />
              <img className="picture" src={'/img/single/cart-icon.png'} width="57" height="48" alt="Cart" />
            </div>
            <a className="button visitwebsite" href="https://www.southmelbournemarket.com.au" target="_blank" rel="noreferrer"><span className="seemore"><i>&#xe801;</i></span>Visit Website</a>
          </div>
        </div>

      </div>
    </section>

    <section id="single-content">
      <div className="single-content">
        <p>South Melbourne Market is the quintessential village market, a place where people come not only to purchase fresh food, but to meet, eat, drink, shop, discover, share and connect.</p>
        <p>Collaborating with a team of designer and digital strategists to deliver customised WordPress and WooCommerce development responsive for desktop, smartphone and tablet. Customised events management and ticketing system powered by WordPress with our content management interface. South Melbourne Market is owned and operated by the City of Port Phillip.</p>
      </div>

      <div className="project-image">
        <div className="left-col">
          <img className="picture" src={'/img/single/SMM-1.jpg'} alt="Desktop View" />
        </div>
        <div className="right-col">
          <img className="picture" src={'/img/single/SMM-2.jpg'} alt="Mobile View" />
      </div>
      </div>
    </section>


    <section id="post-pagination">
      <div className="post-pagination">
        <div className="previous">
          <AniLink cover to="/work/bmg" bg="#00ccd4" direction="right" duration={0.76} className="button project prev">Prev Work</AniLink>
          <p className="project-name">BMG</p>
        </div>
        <div className="next">
          <AniLink cover to="/work/we-make-online-videos" bg="#00ccd4" direction="right" duration={0.76} className="button project next">Next Work</AniLink>
          <p className="project-name">We Make Online Videos</p>
        </div>
      </div>
    </section>
</Layout>

  </>
  )
}

export default smm


export const Head = () => (
  <>
    <title>South Melbourne Market - Tobstar</title>
<meta name="description" content="South Melbourne Market is the quintessential village market, a place where people come not only to purchase fresh food, but to meet, eat, drink, shop, discover, share and connect." />
  </>
)